import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import 'swiper/css/bundle'

const swiperContainer = document.querySelector(".hero-block__slider");
if (swiperContainer !== null) {
  const params = {
      modules: [Navigation, Pagination, Autoplay, EffectFade],
      slidesPerView: 1,
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
       effect: "fade",
      pagination: {
        el: '.swiper-pagination',
        type: "bullets",
        clickable: true,
      },
      navigation: false,
  };

  new Swiper(swiperContainer, params)
}
